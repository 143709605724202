import React, { useEffect, useState } from "react";
import { fetchGet } from "../utils/fetchGet";
import { BASE_URL } from "../constants/appConstants";
import Spinner from "../utils/spinner.svg";
import { options } from "./patient-evaluation";

const EvaluationList = () => {
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentProcessing, setCurrentProcessing] = useState(0);
  const [evaluationStarted, setEvaluationStarted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const init = async () => {
    try {
      setLoading(true);
      const res = await fetchGet(`${BASE_URL}/requests/all`);
      console.log(res?.data?.data);
      setRequests(res?.data?.data);
     
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const startProcessing = () => {
    setEvaluationStarted(true);
    setCurrentProcessing(0);
    const animationDuration = 2500; // Duration between each item animation in milliseconds
    const totalDuration = requests.length * animationDuration;

    const timer = setInterval(() => {
      setCurrentProcessing((prev) => prev + 1);
    }, animationDuration);

    // Clear the interval after all items have been animated
    setTimeout(() => clearInterval(timer), totalDuration);
  };

  return (
    <div className="container position-relative mt-4 px-4 animate-left-to-right">
      <p className="ff-os fc-cprimary m-0 fs-20 fw-600 mb-3">Requests List</p>
      {loading ? (
        <div className="w-100 h-100 d-flex align-items-center mt-3">
          <div class="spinner-grow bg-cprimary" role="status" />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-end py-2 mb-4">
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              placeholder="Search"
              className="form-control shadow-sm border-cprimary w-25"
            />
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="fs-14 fw-600">Request Title</th>
                <th className="fs-14 fw-600">Create Date</th>
                <th className="fs-14 fw-600">Policies</th>
                <th className="fs-14 fw-600">Insurance Company</th>
                <th className="fs-14 fw-600">Request Status</th>
              </tr>
            </thead>
            <tbody className="border">
              {requests
                ?.filter(
                  (item) =>
                    item?.title
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase()) ||
                    item.insuranceCompany?.[0]?.label
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase()) ||
                    item.requestStatus
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase()) ||
                    JSON.stringify(item.policies)
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                )
                ?.map((item, index) => {
                  const isProcessing =
                    evaluationStarted && currentProcessing === index;
                  const isWaiting =
                    evaluationStarted && currentProcessing < index;
                  const processingCompleted =
                    evaluationStarted && currentProcessing > index;

                  return (
                    <tr
                      key={index}
                      className={`${
                        currentProcessing === index && evaluationStarted
                          ? "shadow-lg"
                          : ""
                      }`}
                    >
                      <td className={`fs-14 my-2 ${isProcessing ? "fw-500 fc-cprimary" : "fw-400"}`}>
                        <p>{item?.title}</p>
                      </td>
                      <td className={`fs-14 ${isProcessing ? "fw-500 fc-cprimary" : "fw-400"}`}>
                        {" "}
                        {new Date(item?.createdAt)?.toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className={`fs-14 ${isProcessing ? "fw-500 fc-cprimary" : "fw-400"}`}>
                        {item?.policies?.map((pol, index) => (
                          <p className={`m-0 fs-14 ${isProcessing ? "fw-500 fc-cprimary" : "fw-400"}`}>
                            {index + 1}. {pol?.label}
                          </p>
                        ))}
                      </td>
                      <td className={`fs-14 ${isProcessing ? "fc-cprimary fw-500" : "fw-400"}`}>
                        {item.insuranceCompany?.[0]?.label}
                      </td>

                      <td
                        style={{
                          width: 225,
                        }}
                        className="fs-14 fw-500"
                      >
                        <div>
                          {isProcessing && (
                            <div className="d-flex">
                              <p className="m-0 fw-500">Processing... </p>
                              <img
                                className="ms-4"
                                width={28}
                                alt="loader"
                                height={28}
                                src={Spinner}
                              />
                            </div>
                          )}
                          {processingCompleted && (
                            <div className="d-flex align-items-center">
                              <p className="m-0">Completed</p>
                              <button className="btn fs-14 ms-4 fw-600 p-0 m-0 fc-cprimary d-flex align-items-center">
                                View results
                                <i class="bi bi-check2-circle fs-14 fw-600 ms-2"></i>
                              </button>
                            </div>
                          )}
                          {isWaiting && (
                            <div className="d-flex align-items-center">
                              <p className="m-0">Waiting...</p>
                            </div>
                          )}
                          {!evaluationStarted && (
                            <div>{item.requestStatus}</div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="d-flex align-items-end justify-content-end">
            <button
              onClick={startProcessing}
              className="btn bg-cprimary border-cprimary fw-500 fs-16 px-4"
            >
              Evaluate all Requests
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationList;
