import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routePaths } from "../App";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Swal from "sweetalert2";
import { fetchPost } from "../utils/fetchPost";
import { BASE_URL } from "../constants/appConstants";
import { fetchGet } from "../utils/fetchGet";
import axios from "axios";
import { RequestValidationTrees } from "../components/validation/generated"

const options = {
  year: "numeric",
  month: "long", // 'long' for full month name, 'short' for abbreviated month, 'numeric' for number
  day: "numeric",
};

const animatedComponents = makeAnimated();

let policiesData = null;
let insuranceCompanies = null;

export const RequestDetails = () => {
  const [showEvaluate, setShowEvaluate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState(null);
  const [selectedInsComp, setSelectedInsComp] = useState(null);
  const [priorAuthInp, setPriorAuthInput] = useState("");
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedRequest, setSelectedRequest] = useState({
    type: "new",
  });
  const [title, setTitle] = useState("");
  const [turnOnEditMode, setTurnOnEditMode] = useState(false);


  const init = async () => {
    try {
      setLoading(true);
      const res = await fetchGet(`${BASE_URL}/requests/all`);
      setRequests(res?.data?.data);
      
        if(insuranceCompanies == null) {
            const res = await fetchGet(`${BASE_URL}/get-policies-by-source`);
            policiesData = res.data;
            insuranceCompanies = [];
            for (let [key, value] of Object.entries(res.data)) {
                insuranceCompanies.push({
                    label: key,
                    value: key
                })
            }
            Swal.fire("Done!", "Data Retrieved", "success");
        }

    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const getPoliciesOptions = () => {
    if(policiesData == null || selectedInsComp == null || selectedInsComp.length == 0) {
        return [];
    }

    let insComp;
    if(Array.isArray(selectedInsComp)) {
        insComp = selectedInsComp[0].value
    } else {
        insComp = selectedInsComp.value
    }
    if(!policiesData[insComp]) {
        return []; 
    }
    let policies = [];
    for(let item of policiesData[insComp]) {
        policies.push({
            label: item["title"],
            value: item["id"]
        })
    }
    return policies;
  };

  const findPolicies = async () => {
    setShowLoader(true);
    try {
        const res = await axios.post(`${BASE_URL}/find-policies`, {
            text: priorAuthInp,
            source: selectedInsComp.value
        });
        setSelectedPolicies(res.data.map((policy) => {
            return {label: policy.title, value: policy.id}
        }));

        Swal.fire("Done!", "Relevant Policies Retrieved", "success");

    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmitRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/add`, "post", {
        priorAuthReqDoc: priorAuthInp,
        policies: selectedPolicies,
        insuranceCompany: selectedInsComp,
        title,
      });
      console.log(res);

      setSelectedInsComp(null);
      setSelectedPolicies(null);
      // setFileName("No file chosen");
      setPriorAuthInput("");
      setTitle("");

      Swal.fire({
        title: "Done!",
        text: "Your request has been saved.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleModifyRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/modify`, "post", {
        priorAuthReqDoc: priorAuthInp,
        policies: selectedPolicies,
        insuranceCompany: selectedInsComp,
        title,
        id: selectedRequest?.data?._id,
      });

      setSelectedRequest({
        type: "old",
        data: {
          ...selectedRequest?.data,
          title,
          insuranceCompany: selectedInsComp,
          policies: selectedPolicies,
          priorAuthReqDoc: priorAuthInp,
        },
      });

      setTurnOnEditMode(false);

      Swal.fire({
        title: "Done!",
        text: "Your request has been modified.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/delete`, "post", {
        id: selectedRequest?.data?._id,
      });

      setSelectedRequest({ type: "new" });
      setPriorAuthInput("");
      setTitle("");
      setSelectedPolicies(null);
      setSelectedInsComp(null);
      setTurnOnEditMode(false);

      init();

      Swal.fire({
        title: "Done!",
        text: "Your request has been deleted.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  if (loading)
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-grow bg-cprimary" role="status" />
      </div>
    );
  return (
    <div className="container h-m-100 animate-left-to-right px-4">
      <div className="w-100 my-2 mt-4">
        <Link to={routePaths.root}>
          <i class="bi bi-arrow-left-circle fs-24 fw-500 fc-cprimary pointer"></i>
        </Link>
      </div>
      <div className="row">
        <div className="col-3 mt-4">
            <div style={{position: "sticky", top: 10}}>
          <div>
            {requests?.length === 0 ? (
              <p className="m-0 fw-400 fs-16 mb-2">
                Please add your first request.
              </p>
            ) : (
              <input
                className="form-control shadow-sm mb-4"
                value={query}
                onChange={(e) => setQuery(e?.target?.value)}
                placeholder="Search request"
              />
            )}
          </div>
          <div className="">
            {requests
              ?.filter((req) => req?.title?.toString()?.includes(query))
              ?.map((req) => (
                <div
                  onClick={() => {
                    setSelectedRequest({ type: "old", data: req });
                    setPriorAuthInput(req?.priorAuthReqDoc);
                    setTitle(req?.title);
                    setSelectedPolicies(req?.policies);
                    setSelectedInsComp(req?.insuranceCompany);
                    setTurnOnEditMode(false);
                  }}
                  className={`border mb-3 pointer rounded p-2 border-cprimary ${
                    selectedRequest?.data?._id === req?._id ? "bg-cprimary" : ""
                  } d-flex align-items-center`}
                >
                  <p className="m-0">{req?.title}</p>
                </div>
              ))}
            <button
              onClick={() => {
                setSelectedRequest({ type: "new" });
                setPriorAuthInput("");
                setTitle("");
                setSelectedPolicies(null);
                setSelectedInsComp(null);
                setTurnOnEditMode(false);
              }}
              className={`btn btn-${
                selectedRequest?.type === "old" ? "outline-" : ""
              }secondary w-100`}
              type="button"
            >
              Add a New Request
            </button>
          </div>
          </div>
        </div>
        {selectedRequest?.type === "new" ? (
          <div className="col-4">
            <div className="row" style={{position: "sticky", top: 10}}>
                <p className="m-0 fw-500 fs-18 mt-4 mb-2">
                {new Date(Date.now())?.toLocaleDateString("en-US", options)}
                </p>
              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Title</p>
                <input
                  className="border-cprimary rounded w-100 p-2"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                  placeholder="Add a title"
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Prior Auth request Document
                </p>
                <textarea
                  onInput={(e) => setPriorAuthInput(e?.target?.value)}
                  value={priorAuthInp}
                  className="border-cprimary rounded w-100 p-2"
                  rows={10}
                  disabled={showLoader}
                ></textarea>
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Select Insurance Company{" "}
                </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  onChange={setSelectedInsComp}
                  value={selectedInsComp}
                  options={insuranceCompanies}
                  className="border-cprimary"
                  isDisabled={showLoader}
                />
              </div>

              <div className="my-3 mt-4">
                <button
                    onClick={findPolicies}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                    disabled={showLoader || priorAuthInp == "" || selectedInsComp == null}
                    >
                    Find relevant policies
                    </button>
                </div>
                
              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Select policies: </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  isMulti
                  value={selectedPolicies}
                  onChange={setSelectedPolicies}
                  options={getPoliciesOptions()}
                  isDisabled={showLoader}
                  className="border-cprimary"
                />
                <div className="mt-2">
                  {selectedPolicies?.map((pol, index) => (
                    <div
                      className="my-2 d-flex justify-content-between"
                      key={pol?.key}
                    >
                      <p className="m-0 fw-400 fs-16 fc-cprimary">
                        {index + 1}. {pol?.label}
                      </p>
                      <i class="bi bi-check-circle-fill fc-cprimary fs-18"></i>
                    </div>
                  ))}
                </div>
              </div>

              {showLoader ? (
                <div className="col-6 p-4">
                  <div class="spinner-grow bg-cprimary" role="status"></div>
                  <p className="fs-16 fw-500 fc-cprimary m-0">
                    Please wait while loading...
                  </p>
                </div>
              ) : (
                <div className="my-3 mt-4">
                  <button
                    onClick={handleSubmitRequest}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                  >
                    Submit a request
                  </button>
                </div>
              )}

            </div>
          </div>
        ) : (
          <div className="col-4">
            <div className="row" style={{position: "sticky", top: 10}}>
              <div className="col-11 d-flex align-items-center justify-content-between mt-4 mb-2">
                <p className="m-0 fw-500 fs-18">
                  {new Date(
                    selectedRequest?.data?.createdAt
                  )?.toLocaleDateString("en-US", options)}
                </p>
                <div>
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDeleteRequest();
                          // Perform the action for Yes
                        }
                      });
                    }}
                    className="btn btn-outline-danger"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                  <button
                    onClick={() => {
                      setTurnOnEditMode((prev) => !prev);
                    }}
                    className={`btn btn${
                      !turnOnEditMode ? "-outline" : ""
                    }-secondary ms-4`}
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
              </div>
              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Title</p>
                <input
                  className="border-cprimary rounded w-100 p-2"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                  placeholder="Add a title"
                  disabled={!turnOnEditMode}
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Prior Auth request Document
                </p>
                <textarea
                  onInput={(e) => setPriorAuthInput(e?.target?.value)}
                  value={priorAuthInp}
                  className="border-cprimary rounded w-100 p-2"
                  rows={10}
                  // disabled={showLoader}
                  disabled={!turnOnEditMode}
                ></textarea>
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Select Insurance Company{" "}
                </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  onChange={setSelectedInsComp}
                  value={selectedInsComp}
                  options={insuranceCompanies}
                  className="border-cprimary"
                  // isDisabled={showLoader}
                  isDisabled={!turnOnEditMode}
                />
              </div>

              <div className="my-3 mt-4">
                <button
                    onClick={findPolicies}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                    disabled={!turnOnEditMode || showLoader || priorAuthInp == "" || selectedInsComp == null}
                    >
                    Find relevant policies
                    </button>
                </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Select policies: </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  isMulti
                  value={selectedPolicies}
                  onChange={setSelectedPolicies}
                  options={getPoliciesOptions()}
                  // isDisabled={showLoader}
                  className="border-cprimary"
                  isDisabled={!turnOnEditMode}
                />
                <div className="mt-2">
                  {selectedPolicies?.map((pol, index) => (
                    <div
                      className="my-2 d-flex justify-content-between"
                      key={pol?.key}
                    >
                      <p className="m-0 fw-400 fs-16 fc-cprimary">
                        {index + 1}. {pol?.label}
                      </p>
                      <i class="bi bi-check-circle-fill fc-cprimary fs-18"></i>
                    </div>
                  ))}
                </div>
              </div>

              {showLoader ? (
                <div className="col-11 p-4">
                  <div class="spinner-grow bg-cprimary" role="status"></div>
                  <p className="fs-16 fw-500 fc-cprimary m-0">
                    Please wait while loading...
                  </p>
                </div>
              ) : (
                <div className="my-3 mt-4">
                  <button
                    onClick={handleModifyRequest}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                  >
                    Save the changes
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="col-4">
            <RequestValidationTrees requestId={selectedRequest?.data?._id} ></RequestValidationTrees>
        </div>
      </div>
    </div>
  );
};
