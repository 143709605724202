// export const BASE_URL = "http://localhost:3000";
export const BASE_URL = "https://app.generative-technologies.com";

export const APP_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  APP_USER: "APP_USER",
};

export const ACCESSED_TABS = {
  SEARCH: "SEARCH",
  COVERAGE_VALIDATION: "COVERAGE_VALIDATION",
  REQUESTS_LIST: "REQUESTS_LIST",
};
