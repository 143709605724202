import React, { useState, useEffect } from "react";
import axios from "axios";
import { AlertCircle, CheckCircle, Loader2 } from "lucide-react";
import Swal from "sweetalert2";

const address = process.env.REACT_APP_BACKEND_URL;

const Question = ({ question, onAnswerChange, answers }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {question.question}
      </label>
      <p className="text-sm text-gray-500">{question.type === "indication" ? "Indication" : "Contraindication"}</p>
      <select
        className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary"
        onChange={(e) => onAnswerChange(question.questionId, {
          response: e.target.value,
          reason: question.questionId in answers ? answers[question.questionId].reason : ""
        })}
        value={question.questionId in answers ? answers[question.questionId].response : -1}
      >
        {question.possible_answers.map((answer, idx) => (
          <option key={idx} value={answer}>
            {answer}
          </option>
        ))}
        <option value={-1}>Not filled</option>
      </select>
      <textarea
        onInput={(e) => onAnswerChange(question.questionId, {
          reason: e.target.value,
          response: question.questionId in answers ? answers[question.questionId].response : ""
        })}
        value={question.questionId in answers ? answers[question.questionId].reason : ""}
        className="mt-2 w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary"
        rows={2}
        placeholder="Enter reason"
      ></textarea>
    </div>
  );
};

const ValidationNode = ({ validationTree, onAnswerChange, answers }) => {
  return (
    <div className="ml-4">
      {validationTree.or ? (
        validationTree.or.map((node, idx) => (
          <ValidationNode
            key={idx}
            validationTree={node}
            onAnswerChange={onAnswerChange}
            answers={answers}
          />
        ))
      ) : validationTree.and ? (
        validationTree.and.map((node, idx) => (
          <ValidationNode
            key={idx}
            validationTree={node}
            onAnswerChange={onAnswerChange}
            answers={answers}
          />
        ))
      ) : (
        <Question
          question={validationTree}
          onAnswerChange={onAnswerChange}
          answers={answers}
        />
      )}
    </div>
  );
};

const Criteria = ({ criteria, onAnswerChange, status, answers }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <h6 className="text-lg font-semibold mb-2 flex items-center">
        {criteria.title}
        {status === "Covered" ? (
          <CheckCircle className="ml-2 text-green-500" size={20} />
        ) : (
          <AlertCircle className="ml-2 text-red-500" size={20} />
        )}
      </h6>
      <p className="text-gray-600 mb-4">{criteria.description}</p>
      {criteria.validation_tree ? <ValidationNode
        validationTree={criteria.validation_tree}
        onAnswerChange={onAnswerChange}
        answers={answers}
      />: ""}
    </div>
  );
};

const PolicyTree = ({ tree, answers, setAnswers, statuses, setStatuses }) => {
  const [policyStatus, setPolicyStatus] = useState(null);

  const onAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value
    }));
  };

  useEffect(() => {
    const calculateStatus = () => {
      let overallStatus = true;
      let updatedStatuses = tree.criteria.map((criteria) => {
        let isCovered = false;
        const validateTree = (currentNode) => {
          if (currentNode.or) {
            return currentNode.or.some((node) => validateTree(node));
          } else if (currentNode.and) {
            return currentNode.and.every((node) => validateTree(node));
          } else {
            return currentNode.questionId in answers ? answers[currentNode.questionId].response === currentNode.coverage_response : false;
          }
        };
        isCovered = criteria.validation_tree? validateTree(criteria.validation_tree): false;
        if (!isCovered) overallStatus = false;
        return isCovered ? "Covered" : "Not Covered";
      });
      setStatuses((prev) => ({
        ...prev,
        [tree.id]: updatedStatuses,
      }));
      setPolicyStatus(overallStatus);
    };
    calculateStatus();
  }, [answers, tree, setStatuses]);

  return (
    <div className={`p-6 rounded-lg mb-8 ${policyStatus ? "bg-green-50" : "bg-red-50"}`}>
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-bold">{tree.title}</h4>
      </div>
      <p className="text-gray-600 mb-6">{tree.description}</p>
      {tree.criteria.map((criteria, index) => (
        <Criteria
          key={index}
          criteria={criteria}
          onAnswerChange={onAnswerChange}
          answers={answers}
          status={statuses[tree.id]?.[index] || "Not Covered"}
        />
      ))}
    </div>
  );
};

export const ValidationTree = ({ trees, answers, setAnswers }) => {
  const [statuses, setStatuses] = useState({});
  return (
    <div>
      {trees.map((tree) => (
        <PolicyTree
          key={tree.id}
          tree={tree}
          answers={answers}
          setAnswers={setAnswers}
          statuses={statuses}
          setStatuses={setStatuses}
        />
      ))}
    </div>
  );
};

export const RequestValidationTrees = ({ requestId }) => {
  const [loading, setLoading] = useState(false);
  const [filling, setFilling] = useState(false);
  const [saving, setSaving] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const getData = async () => {
      if (requestId != null) {
        setLoading(true);
        try {
          const [policiesRes, answersRes] = await Promise.all([
            axios.post(`${address}/get-request-trees`, { requestId }),
            axios.post(`${address}/get-responses`, { requestId })
          ]);
          setPolicies(policiesRes.data);
          setAnswers(answersRes.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };
    getData();
  }, [requestId]);

  const autoFillAnswers = async () => {
    setFilling(true);
    try {
      const res = await axios.post(`${address}/check-coverage`, {
        requestId: requestId,
      });
      setAnswers(res.data);
      Swal.fire("Done!", "Responses Generated", "success");
    } catch (err) {
      console.error(err);
      Swal.fire("Error!", "Please Try again", "error");
    } finally {
      setFilling(false);
    }
  };

  const saveResponses = async () => {
    setSaving(true);
    try {
      await axios.post(`${address}/save-responses`, {
        requestId: requestId,
        responses: answers
      });
      Swal.fire("Done!", "Responses Saved", "success");
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold text-center mb-6">Coverage Assessment</h3>
      <hr className="mb-6" />
      {requestId == null ? (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
          <p>Please select a saved request or save your request to show.</p>
        </div>
      ) : policies.length === 0 ? (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
          <p>No policies assigned to this request.</p>
        </div>
      ) : (
        <div>
          <div className="flex justify-center space-x-4 mb-6">
            <button
              className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition-colors"
              onClick={autoFillAnswers}
              disabled={filling}
            >
              {filling ? "Filling ..." : "Auto-Fill Answers"}
            </button>
            <button
              className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition-colors"
              onClick={saveResponses}
              disabled={saving}
            >
              {saving ? "Saving ..." : "Save Responses"}
            </button>
          </div>
          <ValidationTree trees={policies} answers={answers} setAnswers={setAnswers} />
        </div>
      )}
    </div>
  );
};