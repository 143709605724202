import { Link, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AppContext,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from "../../context/AppContext";
import { routePaths } from "../../App";
import { ACCESSED_TABS, APP_ROLES } from "../../constants/appConstants";

export const Sidebar = ({ expandSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currPath, setCurrPath] = useState(false);
  const refinementListRef = useRef();
  const { appState, setAppState } = useContext(AppContext);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    appState?.sidebarWidth === SIDEBAR_EXPANDED_WIDTH
  );

  useEffect(() => {
    setCurrPath(location.pathname);
  }, [location]);

  const validateVisibilityOfTab = (tabName) => {
    return (
      appState?.userData?.role === APP_ROLES.SUPER_ADMIN ||
      appState?.userData?.accessed_tabs?.includes(tabName)
    );
  };

  return !["/login"]?.includes(currPath) ? (
    <div className="">
      <div className="d-flex h-100">
        <div
          style={{
            width: isSidebarExpanded
              ? SIDEBAR_EXPANDED_WIDTH
              : SIDEBAR_COLLAPSED_WIDTH,
          }}
        />

        <div
          className="position-fixed pt-4 h-100 flex-column d-flex align-items-start justify-center-start px-4 sidebar-div custom-scroll-bar"
          style={{
            width: isSidebarExpanded
              ? SIDEBAR_EXPANDED_WIDTH
              : SIDEBAR_COLLAPSED_WIDTH,
          }}
        >
          <button
            className="btn p-2 bg-cprimary"
            onClick={() => {
              setIsSidebarExpanded((prev) => !prev);
            }}
          >
            <i
              class={`bi bi-${isSidebarExpanded ? "x" : "list"} fs-${
                isSidebarExpanded ? "20" : "20"
              }`}
            ></i>
          </button>
          {validateVisibilityOfTab(ACCESSED_TABS.SEARCH) && (
            <Link
              className="fs-16 fw-500 my-2 fc-cprimary"
              to={routePaths.root}
            >
              <i
                class={`p-2 bi bi-search fs-${isSidebarExpanded ? "16" : "20"}`}
              ></i>
              {isSidebarExpanded && <span className="ms-2 fs-14">Search</span>}
            </Link>
          )}
          {/* <Link
            className="fs-16 fw-500 my-2 fc-cprimary"
            to={routePaths.patientEvaluation}
          >
            <i
              class={`p-2 bi bi-check-square fs-${
                isSidebarExpanded ? "16" : "20"
              }`}
            ></i>
            {isSidebarExpanded && <span className="ms-2 fs-14"> Evaluation </span>}
          </Link> */}

          {/* <Link
            className="fs-16 fw-500 my-2 fc-cprimary"
            to={routePaths.evaluationList}
          >
            <i class="bi bi-list-ul"></i>
            <span className="ms-2"> Evaluation list</span>
          </Link> */}
          {validateVisibilityOfTab(ACCESSED_TABS.COVERAGE_VALIDATION) && (
            <Link
              className="fs-16 fw-500 my-2 fc-cprimary"
              to={routePaths.requestDetails}
            >
              <i
                class={`p-2 bi bi-bookmark-check fs-${
                  isSidebarExpanded ? "16" : "20"
                }`}
              ></i>
              {isSidebarExpanded && (
                <span className="ms-2 fs-14"> Coverage Validation</span>
              )}
            </Link>
          )}

          {validateVisibilityOfTab(ACCESSED_TABS.REQUESTS_LIST) && (
            <Link
              className="fs-16 fw-500 my-2 fc-cprimary"
              to={routePaths.evaluationList}
            >
              <i
                class={`p-2 bi bi-list-ol fs-${
                  isSidebarExpanded ? "16" : "20"
                }`}
              ></i>
              {isSidebarExpanded && (
                <span className="ms-2 fs-14"> Requests List </span>
              )}
            </Link>
          )}
          {/* <button
            className="outline-none border-none bg-none py-2 d-flex justify-content-center px-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Expand menu"
            onClick={() => {
              // setAppState((curr) => ({
              //   ...curr,
              //   sidebarWidth:
              //     curr?.sidebarWidth === 100 ? SIDEBAR_EXPANDED_WIDTH : 100,
              // }));
            }}
            style={{
              background: "none",
            }}
          >
            {!isSidebarExpanded ? (
              <i class="bi bi-layout-sidebar fs-24"></i>
            ) : (
              <i class="bi bi-layout-sidebar-inset fs-24 hello-greeting"></i>
            )}
          </button> */}
          <div className="d-flex">
            {/* <RefinementList
              transformItems={(items) =>
                items.map((item) => ({
                  ...item,
                  isRefined: ["Drama", "Comedy"].includes(
                    item.label
                  ), // Replace with your default values
                }))
              }
              ref={refinementListRef}
              attribute="genres"
              key={"genres"}
            /> */}

            {/* <ConnectedCustomRefinementList attribute="type" /> */}
            {/* {currPath === routePaths.root && (
            )}
            {currPath === routePaths.chatWithAI && (
              <MoviesHitsSidebar attribute="date" />
            )} */}
          </div>
          {/* <div className="w-100 mt-4">
            <button
              className={`outline-none btn border-none w-75 fs-16 px-2 d-flex align-items-center ps-2 ${currPath === "/" ? "fc-cprimary fw-500" : ""}`}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Expand menu"
              onClick={() => {
                navigate("/");
              }}
            >
              <i class={`bi bi-filter-circle fs-20`}></i>
              {isSidebarExpanded && (
                <span className="text-start w-100 fs-14 ms-3">
                  Refine Documents
                </span>
              )}
            </button>
          </div> */}
          {/* 
          <div className="w-100 mt-4">
            <button
              className={`outline-none btn border-none w-75 fs-16 px-2 d-flex align-items-center ps-2 ${currPath === "/chat-with-ai" ? "fc-cprimary fw-500" : ""}`}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Expand menu"
              onClick={() => {
                navigate("/chat-with-ai");
              }}
            >
              <i class="bi bi-chat fs-20"></i>
              {isSidebarExpanded && (
                <span className="text-start w-100 fs-14 ms-3">
                  Chat with ai
                </span>
              )}
            </button>
          </div> */}

          {/* <div className="w-100 mt-4">
            <button
              className="outline-none border-none w-75 px-2 d-flex align-items-center ps-2 sidebar-button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Expand menu"
              onClick={() => {
                navigate("/quick-filter");
              }}
            >
              <i class="bi bi-filter fs-24"></i>
              {expandSidebar && (
                <span className="text-center w-100">New Chat</span>
              )}
            </button>
          </div> */}

          {/* {expandSidebar && (
            <div className="ms-2 mt-4">
              <p className="ff-os m-0 mb-2 ff-os">Recent</p>
              {[
                "What is the Montana general rule and its two exceptions?",
                "What is the tribal exhaustion doctrine?",
                "What is membership based tribal jurisdiction?",
                "What criminals can Indian tribes prosecute?",
                "What is the difference between Class II and Class III Indian gaming?",
              ]?.map((recentQuery, index) => (
                <div className="d-flex my-2" key={`RECENT_${index}`}>
                  <i class="bi bi-chat-left-text me-2"></i>
                  <p className="ff-os m-0 ff-os fs-14 fw-500 recent-query-text">
                    {recentQuery}
                  </p>
                </div>
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
