import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routePaths } from "../App";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import typesense from "../typesenseAdapter";
import Swal from "sweetalert2";
import axios from "axios";
import { fetchPost } from "../utils/fetchPost";
import { ValidationTree } from "../components/validation/validation-tree"
import { v4 as uuidv4 } from 'uuid';

const trees = [
    {
      policyId: 1,
      title: "Home Oximetry Monitoring",
      description:
        "Policy for intermittent home oximetry monitoring for individuals with chronic lung disease, severe cardiopulmonary disease, or neuromuscular disease.",
      criteria: [
        {
          title: "Diagnosis Criteria",
          description:
            "The individual must be diagnosed with chronic lung disease, severe cardiopulmonary disease, or neuromuscular disease involving muscles of respiration.",
          validation_tree: {
            or: [
              {
                question:
                  "Has the individual been diagnosed with chronic lung disease?",
                answers: ["Yes", "No", "NA"],
                type: "indication",
                coverage_response: "Yes",
              },
              {
                question:
                  "Has the individual been diagnosed with severe cardiopulmonary disease?",
                answers: ["Yes", "No", "NA"],
                type: "indication",
                coverage_response: "Yes",
              },
            ],
          },
        },
        {
          title: "Change in Condition",
          description:
            "There must be a change in the individual’s physical condition requiring a physician-directed adjustment in the oxygen liter flow.",
          validation_tree: {
            and: [
              {
                question:
                  "Has there been a change in the individual's physical condition?",
                answers: ["Yes", "No", "NA"],
                type: "indication",
                coverage_response: "Yes",
              },
              {
                question:
                  "Is this change requiring a physician-directed adjustment in the oxygen liter flow?",
                answers: ["Yes", "No", "NA"],
                type: "indication",
                coverage_response: "Yes",
              },
            ],
          },
        },
      ],
    },
    {
      policyId: 2,
      title: "Telespirometry Monitoring",
      description:
        "Policy for telespirometry monitoring after a lung transplant, integral for up to one year post-transplant.",
      criteria: [
        {
          title: "Post-Transplant Criteria",
          description:
            "The individual must be within one year post lung transplant to be eligible for telespirometry monitoring.",
          validation_tree: {
            and: [
              {
                question:
                  "Is the individual within one year post lung transplant?",
                answers: ["Yes", "No", "NA"],
                type: "indication",
                coverage_response: "Yes",
              },
            ],
          },
        },
      ],
    },
  ];

const animatedComponents = makeAnimated();
const old_policies = [
  {
    label: "Hyperbaric Oxygen Therapy, Topical Oxygen Therapy",
    value: "HUM-0450-027",
  },
  {
    label: "Home Oximetry Monitoring",
    value: "HUM-0431-017",
  },
  {
    label: "Home Health",
    value: "HUM-0329-029",
  },
  {
    label: "Hip, Knee and Shoulder Arthroscopic Surgeries - Medicare Advantage",
    value: "HUM-1124-001",
  },
  {
    label: "Hip, Knee and Shoulder Arthroscopic Surgeries",
    value: "HUM-0540-013",
  },
  {
    label: "Headache and Occipital Neuralgia Treatments - Medicare Advantage",
    value: "HUM-1122-000",
  },
  {
    label: "Hammer Toe Surgical Treatments",
    value: "HUM-0542-008",
  },
  {
    label: "Zynteglo (betibeglogene autotemcel) - Medicare Advantage",
    value: "HUM-1239-000",
  },
  {
    label: "Pulmonary Rehabilitation",
    value: "HUM-0338-017",
  },
  {
    label:
      "Proton Beam, Neutron Beam and Carbon Ion Radiation Therapy - Medicare Advantage",
    value: "HUM-1195-000",
  },
  {
    label: "Proton Beam, Neutron Beam and Carbon Ion Radiation Therapy",
    value: "HUM-0369-019",
  },
  {
    label: "Prostatectomy - Medicare Advantage",
    value: "HUM-1193-000",
  },
  {
    label: "Prophylactic Mastectomy",
    value: "HUM-0456-018",
  },
  {
    label: "Preimplantation Genetic Testing",
    value: "HUM-0488-037",
  },
  {
    label: "Pneumatic Compression Pumps",
    value: "HUM-0478-019",
  },
  {
    label:
      "Platelet Derived Growth Factors for Wound Healing - Medicare Advantage",
    value: "HUM-1188-000",
  },
  {
    label: "Plantar Fasciitis Treatments",
    value: "HUM-0558-008",
  },
  {
    label: "Peripheral Artery Revascularization of the Lower Extremities",
    value: "HUM-0583-006",
  },
  {
    label: "Percutaneous Vertebroplasty, Kyphoplasty, Sacroplasty",
    value: "HUM-0367-021",
  },
  {
    label:
      "Percutaneous Vertebroplasty, Kyphoplasty (Balloon-Assisted Vertebroplasty), Sacroplasty - Medicare Advantage",
    value: "HUM-1180-000",
  },
];

const old_insuranceCompanies = [
  { label: "UnitedHealthcare", value: "unitedhealthcare" },
  { label: "Anthem Blue Cross", value: "anthem_blue_cross" },
  { label: "Aetna", value: "aetna" },
  { label: "Cigna", value: "cigna" },
  { label: "Humana", value: "humana" },
  { label: "Kaiser Permanente", value: "kaiser_permanente" },
  { label: "Blue Shield of California", value: "blue_shield_of_california" },
  { label: "Health Care Service Corporation", value: "hcsc" },
  { label: "Centene Corporation", value: "centene_corporation" },
  { label: "Molina Healthcare", value: "molina_healthcare" },
];

let policiesData = null;
let insuranceCompanies = null;

const addUniqueIdentifiers = (p) => {
    let questionIds = [];
    let policies = p.map((policy) => {
        return {
            ...policy,
            criteria: policy.criteria.map((criteria) => {
                const addIdToTree = (validationTree) => {
                    if (validationTree.or) {
                        return {
                            ...validationTree,
                            or: validationTree.or.map((question) => {
                                if (question.or || question.and) {
                                    return addIdToTree(question);
                                } else {
                                    let id = uuidv4();
                                    questionIds.push(id);
                                    return {
                                        ...question,
                                        questionId: id,
                                    };
                                }
                            }),
                        };
                    } else if (validationTree.and) {
                        return {
                            ...validationTree,
                            and: validationTree.and.map((question) => {
                                if (question.or || question.and) {
                                    return addIdToTree(question);
                                } else {
                                    let id = uuidv4();
                                    questionIds.push(id);
                                    return {
                                        ...question,
                                        questionId: id,
                                    };
                                }
                            }),
                        };
                    }
                };
                return {
                    ...criteria,
                    validation_tree: addIdToTree(criteria.validation_tree),
                };
            }),
        };
    });
    return {
        policies, questionIds
    };
};

const address = process.env.REACT_APP_BACKEND_URL;

export const CoverageValidation = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState(null);
  const [selectedInsComp, setSelectedInsComp] = useState(null);
  const [priorAuthInp, setPriorAuthInput] = useState("");
  const [validationTrees, setValidationTrees] = useState({policies: [], questionIds : []});

  const init = async () => {
    setShowLoader(true);
    try {
        if(insuranceCompanies == null) {
            const res = await axios.get(`${address}/get-policies-by-source`);
        
            Swal.fire("Done!", "Data Retrieved", "success");
            policiesData = res.data;
            console.log(policiesData);
            insuranceCompanies = [];
            for (let [key, value] of Object.entries(res.data)) {
                insuranceCompanies.push({
                    label: key,
                    value: key
                })
            }
        }
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const findPolicies = async () => {
    setShowLoader(true);
    try {
        const res = await axios.post(`${address}/find-policies`, {
            text: priorAuthInp,
            source: selectedInsComp.value
        });
        console.log(res);
        setSelectedPolicies(res.data.map((policy) => {
            return {label: policy.title, value: policy.id}
        }));

        Swal.fire("Done!", "Relevant Policies Retrieved", "success");

    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const validate = async () => {
    setShowLoader(true);
    try {
        let ids = []
        for (let [source, value] of Object.entries(policiesData)) {
            for(let item of value) {
                if(selectedPolicies.map(p => p.value).includes(item["id"])) {
                    ids.push(item["id"])
                }
            }
        }
      const res = await axios.post(`${address}/generate-trees`,
        {
            ids: ids
        }
      );
      
      console.log(res.data);

      setValidationTrees(addUniqueIdentifiers(res.data));
      //setValidationTrees(addUniqueIdentifiers(trees));
      

      Swal.fire("Done!", "Validation Trees Generated", "success");

    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const getPoliciesOptions = () => {
    if(policiesData == null || selectedInsComp == null) {
        return [];
    }
    
    let policies = [];
    for(let item of policiesData[selectedInsComp.value]) {
        policies.push({
            label: item["title"],
            value: item["id"]
        })
    }
    return policies;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="container h-m-100 animate-left-to-right px-4">
      <div className="w-100 my-2 mt-4">
        <Link to={routePaths.root}>
          <i class="bi bi-arrow-left-circle fs-24 fw-500 fc-cprimary pointer"></i>
        </Link>
      </div>
      <div className="row">
        <div className="col-6">
            <div className="my-3">
            <p className="m-0 fw-500 fs-20 mb-3">Prior Auth request Document</p>
            <textarea
                onInput={(e) => setPriorAuthInput(e?.target?.value)}
                value={priorAuthInp}
                className="border-cprimary rounded w-100 p-2"
                rows={10}
                disabled={showLoader}
            ></textarea>
            </div>

            <div className="my-3">
            <p className="m-0 fw-500 fs-20 mb-3">Select Insurance Company </p>
            <Select
                components={animatedComponents}
                onChange={(event) => {setSelectedInsComp(event); setSelectedPolicies([])}}
                value={selectedInsComp}
                options={insuranceCompanies}
                className="border-cprimary"
                isDisabled={showLoader}
            />
            </div>

            <div className="my-3 mt-4">
                <button
                onClick={findPolicies}
                type="button"
                className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                disabled={showLoader || priorAuthInp == "" || selectedInsComp == null}
                >
                Find relevant policies
                </button>
            </div>

            <div className="my-3">
            <p className="m-0 fw-500 fs-20 mb-3">Select policies: </p>
            <Select
                components={animatedComponents}
                isMulti
                value={selectedPolicies}
                onChange={setSelectedPolicies}
                options={getPoliciesOptions()}
                isDisabled={showLoader}
                className="border-cprimary"
            />
            <div className="mt-2">
                {selectedPolicies?.map((pol, index) => (
                <div
                    className="my-2 d-flex justify-content-between"
                    key={pol?.key}
                >
                    <p className="m-0 fw-400 fs-16 fc-cprimary">
                    {index + 1}. {pol?.label}
                    </p>
                    <i class="bi bi-check-circle-fill fc-cprimary fs-18"></i>
                </div>
                ))}
            </div>
            </div>

            <div className="my-3 mt-4">
                <button
                onClick={validate}
                type="button"
                className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                disabled={showLoader || priorAuthInp == "" || selectedPolicies == null || selectedPolicies == []}
                >
                Run Validatation
                </button>
            </div>
            
        </div>

        <div className="col-6">
        {showLoader ? (
          <div className="p-4">
            <div class="spinner-grow bg-cprimary" role="status"></div>
            <p className="fs-16 fw-500 fc-cprimary m-0">
              Please wait while Loading...
            </p>
          </div>
        ) : <ValidationTree policies= {validationTrees.policies} questionIds= {validationTrees.questionIds} authRequest={priorAuthInp}></ValidationTree>} 
        </div>
      </div>
    </div>
  );
};
