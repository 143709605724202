import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routePaths } from "../App";

import Select from "react-select";
import makeAnimated from "react-select/animated";
// import typesense from "../typesenseAdapter";
import Swal from "sweetalert2";
import axios from "axios";
import { fetchPost } from "../utils/fetchPost";
import { BASE_URL } from "../constants/appConstants";
import { fetchGet } from "../utils/fetchGet";

export const options = {
  year: "numeric",
  month: "long", // 'long' for full month name, 'short' for abbreviated month, 'numeric' for number
  day: "numeric",
};

const animatedComponents = makeAnimated();
const policies = [
  {
    label: "Hyperbaric Oxygen Therapy, Topical Oxygen Therapy",
    value: "HUM-0450-027",
  },
  {
    label: "Home Oximetry Monitoring",
    value: "HUM-0431-017",
  },
  {
    label: "Home Health",
    value: "HUM-0329-029",
  },
  {
    label: "Hip, Knee and Shoulder Arthroscopic Surgeries - Medicare Advantage",
    value: "HUM-1124-001",
  },
  {
    label: "Hip, Knee and Shoulder Arthroscopic Surgeries",
    value: "HUM-0540-013",
  },
  {
    label: "Headache and Occipital Neuralgia Treatments - Medicare Advantage",
    value: "HUM-1122-000",
  },
  {
    label: "Hammer Toe Surgical Treatments",
    value: "HUM-0542-008",
  },
  {
    label: "Zynteglo (betibeglogene autotemcel) - Medicare Advantage",
    value: "HUM-1239-000",
  },
  {
    label: "Pulmonary Rehabilitation",
    value: "HUM-0338-017",
  },
  {
    label:
      "Proton Beam, Neutron Beam and Carbon Ion Radiation Therapy - Medicare Advantage",
    value: "HUM-1195-000",
  },
  {
    label: "Proton Beam, Neutron Beam and Carbon Ion Radiation Therapy",
    value: "HUM-0369-019",
  },
  {
    label: "Prostatectomy - Medicare Advantage",
    value: "HUM-1193-000",
  },
  {
    label: "Prophylactic Mastectomy",
    value: "HUM-0456-018",
  },
  {
    label: "Preimplantation Genetic Testing",
    value: "HUM-0488-037",
  },
  {
    label: "Pneumatic Compression Pumps",
    value: "HUM-0478-019",
  },
  {
    label:
      "Platelet Derived Growth Factors for Wound Healing - Medicare Advantage",
    value: "HUM-1188-000",
  },
  {
    label: "Plantar Fasciitis Treatments",
    value: "HUM-0558-008",
  },
  {
    label: "Peripheral Artery Revascularization of the Lower Extremities",
    value: "HUM-0583-006",
  },
  {
    label: "Percutaneous Vertebroplasty, Kyphoplasty, Sacroplasty",
    value: "HUM-0367-021",
  },
  {
    label:
      "Percutaneous Vertebroplasty, Kyphoplasty (Balloon-Assisted Vertebroplasty), Sacroplasty - Medicare Advantage",
    value: "HUM-1180-000",
  },
];

const insuranceCompanies = [
  { label: "UnitedHealthcare", value: "unitedhealthcare" },
  { label: "Anthem Blue Cross", value: "anthem_blue_cross" },
  { label: "Aetna", value: "aetna" },
  { label: "Cigna", value: "cigna" },
  { label: "Humana", value: "humana" },
  { label: "Kaiser Permanente", value: "kaiser_permanente" },
  { label: "Blue Shield of California", value: "blue_shield_of_california" },
  { label: "Health Care Service Corporation", value: "hcsc" },
  { label: "Centene Corporation", value: "centene_corporation" },
  { label: "Molina Healthcare", value: "molina_healthcare" },
];

export const PatientEvaluation = () => {
  const [showEvaluate, setShowEvaluate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState(null);
  const [selectedInsComp, setSelectedInsComp] = useState(null);
  const [priorAuthInp, setPriorAuthInput] = useState("");
  // const [fileName, setFileName] = useState("No file chosen");
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedRequest, setSelectedRequest] = useState({
    type: "new",
  });
  const [title, setTitle] = useState("");
  const [turnOnEditMode, setTurnOnEditMode] = useState(false);

  // const handleFileChange = (e) => {
  //   if (e.target.files.length > 0) {
  //     setFileName(e.target.files[0].name);
  //   } else {
  //     setFileName("No file chosen");
  //   }
  // };
  // const init = async () => {
  //   if (!typesense) return;
  //   try {
  //     const response = await typesense
  //       .collections("medicalData-embedded")
  //       .documents()
  //       .search({
  //         per_page: 100,
  //         q: "", // Empty query to fetch all documents or you can specify a query
  //       });

  //     const fetchedOptions = response?.hits.map((hit) => hit?.document?.title); // Replace with the actual field name
  //     console.log(fetchedOptions);
  //   } catch (err) {}
  // };

  const init = async () => {
    try {
      setLoading(true);
      const res = await fetchGet(`${BASE_URL}/requests/all`);
      console.log(res?.data?.data);
      setRequests(res?.data?.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleSubmitRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/add`, "post", {
        priorAuthReqDoc: priorAuthInp,
        policies: selectedPolicies,
        insuranceCompany: selectedInsComp,
        title,
      });
      console.log(res);

      setSelectedInsComp(null);
      setSelectedPolicies(null);
      // setFileName("No file chosen");
      setPriorAuthInput("");
      setTitle("");

      Swal.fire({
        title: "Done!",
        text: "Your request has been saved.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleModifyRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/modify`, "post", {
        priorAuthReqDoc: priorAuthInp,
        policies: selectedPolicies,
        insuranceCompany: selectedInsComp,
        title,
        id: selectedRequest?.data?._id,
      });

      setSelectedRequest({
        type: "old",
        data: {
          ...selectedRequest?.data,
          title,
          insuranceCompany: selectedInsComp,
          policies: selectedPolicies,
          priorAuthReqDoc: priorAuthInp,
        },
      });

      setTurnOnEditMode(false);

      Swal.fire({
        title: "Done!",
        text: "Your request has been modified.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteRequest = async () => {
    setShowLoader(true);
    try {
      const res = await fetchPost(`${BASE_URL}/requests/delete`, "post", {
        id: selectedRequest?.data?._id,
      });

      setSelectedRequest({ type: "new" });
      setPriorAuthInput("");
      setTitle("");
      setSelectedPolicies(null);
      setSelectedInsComp(null);
      setTurnOnEditMode(false);

      init();

      Swal.fire({
        title: "Done!",
        text: "Your request has been deleted.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      window.scrollTo(0, 0);
      init();
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  if (loading)
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-grow bg-cprimary" role="status" />
      </div>
    );
  return (
    <div className="container h-m-100 animate-left-to-right px-4">
      <div className="w-100 my-2 mt-4">
        <Link to={routePaths.root}>
          <i class="bi bi-arrow-left-circle fs-24 fw-500 fc-cprimary pointer"></i>
        </Link>
      </div>
      <div className="row">
        <div className="col-3 mt-4">
          <div>
            {requests?.length === 0 ? (
              <p className="m-0 fw-400 fs-16 mb-2">
                Please add your first request.
              </p>
            ) : (
              <input
                className="form-control shadow-sm mb-4"
                value={query}
                onChange={(e) => setQuery(e?.target?.value)}
                placeholder="Search request"
              />
            )}
          </div>
          <div className="">
            {requests
              ?.filter((req) => req?.title?.toString()?.includes(query))
              ?.map((req) => (
                <div
                  onClick={() => {
                    setSelectedRequest({ type: "old", data: req });
                    setPriorAuthInput(req?.priorAuthReqDoc);
                    setTitle(req?.title);
                    setSelectedPolicies(req?.policies);
                    setSelectedInsComp(req?.insuranceCompany);
                    setTurnOnEditMode(false);
                  }}
                  className={`border mb-3 pointer rounded p-2 border-cprimary ${
                    selectedRequest?.data?._id === req?._id ? "bg-cprimary" : ""
                  } d-flex align-items-center`}
                >
                  <p className="m-0">{req?.title}</p>
                </div>
              ))}
            <button
              onClick={() => {
                setSelectedRequest({ type: "new" });
                setPriorAuthInput("");
                setTitle("");
                setSelectedPolicies(null);
                setSelectedInsComp(null);
                setTurnOnEditMode(false);
              }}
              className={`btn btn-${
                selectedRequest?.type === "old" ? "outline-" : ""
              }secondary w-100`}
              type="button"
            >
              Add a New Request
            </button>
          </div>
        </div>
        {selectedRequest?.type === "new" ? (
          <div className="col-9">
            <p className="m-0 fw-500 fs-18 mt-4 mb-2">
              {new Date(Date.now())?.toLocaleDateString("en-US", options)}
            </p>
            <div className="row">
              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Title</p>
                <input
                  className="border-cprimary rounded w-100 p-2"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                  placeholder="Add a title"
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Prior Auth request Document
                </p>
                <textarea
                  onInput={(e) => setPriorAuthInput(e?.target?.value)}
                  value={priorAuthInp}
                  className="border-cprimary rounded w-100 p-2"
                  rows={10}
                  disabled={showLoader}
                ></textarea>
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Select Insurance Company{" "}
                </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  onChange={setSelectedInsComp}
                  value={selectedInsComp}
                  options={insuranceCompanies}
                  className="border-cprimary"
                  isDisabled={showLoader}
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Select policies: </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  isMulti
                  value={selectedPolicies}
                  onChange={setSelectedPolicies}
                  options={policies}
                  isDisabled={showLoader}
                  className="border-cprimary"
                />
                <div className="mt-2">
                  {selectedPolicies?.map((pol, index) => (
                    <div
                      className="my-2 d-flex justify-content-between"
                      key={pol?.key}
                    >
                      <p className="m-0 fw-400 fs-16 fc-cprimary">
                        {index + 1}. {pol?.label}
                      </p>
                      <i class="bi bi-check-circle-fill fc-cprimary fs-18"></i>
                    </div>
                  ))}
                </div>
              </div>

              {/* <div className="col-11 my-3">
          <p className="m-0 fw-500 fs-20 mb-3"> Upload documents (Optional) </p>

          <div className="custom-file-input">
            <label htmlFor="fileInput" className="file-label">
              Choose File
            </label>
            <input
              disabled={showLoader}
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              className="file-input"
            />
            <span className="file-name">{fileName}</span>
          </div>
        </div> */}

              {showLoader ? (
                <div className="col-6 p-4">
                  <div class="spinner-grow bg-cprimary" role="status"></div>
                  <p className="fs-16 fw-500 fc-cprimary m-0">
                    Please wait while we saving your request...
                  </p>
                </div>
              ) : (
                <div className="my-3 mt-4">
                  <button
                    onClick={handleSubmitRequest}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                  >
                    Submit a request
                  </button>
                </div>
              )}

              {/*   {showEvaluate && (
          <div className="col-6 p-3">
            <p className="fw-500 fs-20 m-0 mb-4">
              Based on the allergy immunotherapy policy document provided,
              here's a concise list of yes/no/NA questions to determine medical
              coverage: Indications (AND logic):
            </p>
            {[
              {
                question:
                  "Does the patient have a diagnosis of allergic rhinitis, allergic conjunctivitis, allergic asthma, or stinging insect hypersensitivity?",
                answer: "Yes",
              },
              {
                question:
                  "  Has the patient's allergy been confirmed through appropriate diagnostic procedures, including clinical listory and specific IgE antibody testing?",
                answer: "Yes",
              },
              {
                question:
                  "Have conservative therapies (including avoidance and pharmacotherapy) failed to adequately control the patient's symptoms?",
                answer: "Yes",
              },
              {
                question:
                  "Is the allergen causing symptoms unavoidable or impractical to avoid in the patient's environment?",
                answer: "Yes",
              },
              {
                question:
                  "Is the immunotherapy being prescribed and administered by a physician trained and experienced in allergy immunotherapy?",
                answer: "Yes",
              },
              {
                question:
                  "Will the immunotherapy be administered in a setting equipped to recognize and manage potential adverse reactions, including anaphylaxis?",
                answer: "Yes",
              },
              {
                question:
                  "Has a customized treatment plan with specified starting dose, target maintenance dose, and schedule been developed for the patient?",
                answer: "Yes",
              },
              {
                question:
                  "Is the patient being treated for a non-covered condition such as food allergy, intrinsic asthma, or non-allergic vasomotor rhinitis?",
                answer: "No",
              },
              {
                question:
                  "Is the proposed immunotherapy using non-standard methods such as oral, sublingual, epicutaneous, or intralymphatic administration?",
                answer: "No",
              },
              {
                question:
                  " Does the treatment involve therapies considered experimental or investigational, such as allergoids, bacterial vaccines, or urine auto-injection?",
                answer: "No",
              },
              {
                question:
                  " Is the patient mentally or physically unable to communicate clearly with the allergist?",
                answer: "No",
              },
              {
                question:
                  "Does the patient have a history of non-compliance with medical treatments?",
                answer: "No",
              },
              {
                question:
                  "For patients with asthma, is the condition poorly controlled or severe?",
                answer: "No",
              },
              {
                question:
                  " Has the immunotherapy treatment extended beyond 24 months without evidence of clinical benefit (decreased symptoms, increased allergen tolerance, and reduced medication usage)?",
                answer: "No",
              },
            ].map((list, index) => (
              <div className="mb-5">
                <p className="m-0 mb-1 fw-400 fs-14 fc-csecondary">
                  {index + 1}. {list?.question}
                </p>
                <p className="text-end m-0 fw-600 fs-20 fc-cprimary">
                  {list?.answer}
                </p>
              </div>
            ))}
          </div>
        )} */}
            </div>
          </div>
        ) : (
          <div className="col-9">
            <div className="row">
              <div className="col-11 d-flex align-items-center justify-content-between mt-4 mb-2">
                <p className="m-0 fw-500 fs-18">
                  {new Date(
                    selectedRequest?.data?.createdAt
                  )?.toLocaleDateString("en-US", options)}
                </p>
                <div>
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDeleteRequest();
                          // Perform the action for Yes
                        }
                      });
                    }}
                    className="btn btn-outline-danger"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                  <button
                    onClick={() => {
                      setTurnOnEditMode((prev) => !prev);
                    }}
                    className={`btn btn${
                      !turnOnEditMode ? "-outline" : ""
                    }-secondary ms-4`}
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
              </div>
              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Title</p>
                <input
                  className="border-cprimary rounded w-100 p-2"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                  placeholder="Add a title"
                  disabled={!turnOnEditMode}
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Prior Auth request Document
                </p>
                <textarea
                  onInput={(e) => setPriorAuthInput(e?.target?.value)}
                  value={priorAuthInp}
                  className="border-cprimary rounded w-100 p-2"
                  rows={10}
                  // disabled={showLoader}
                  disabled={!turnOnEditMode}
                ></textarea>
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">
                  Select Insurance Company{" "}
                </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  onChange={setSelectedInsComp}
                  value={selectedInsComp}
                  options={insuranceCompanies}
                  className="border-cprimary"
                  // isDisabled={showLoader}
                  isDisabled={!turnOnEditMode}
                />
              </div>

              <div className="col-11 my-3">
                <p className="m-0 fw-500 fs-20 mb-3">Select policies: </p>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={[policies[4], policies[5]]}
                  isMulti
                  value={selectedPolicies}
                  onChange={setSelectedPolicies}
                  options={policies}
                  // isDisabled={showLoader}
                  className="border-cprimary"
                  isDisabled={!turnOnEditMode}
                />
                <div className="mt-2">
                  {selectedPolicies?.map((pol, index) => (
                    <div
                      className="my-2 d-flex justify-content-between"
                      key={pol?.key}
                    >
                      <p className="m-0 fw-400 fs-16 fc-cprimary">
                        {index + 1}. {pol?.label}
                      </p>
                      <i class="bi bi-check-circle-fill fc-cprimary fs-18"></i>
                    </div>
                  ))}
                </div>
              </div>

              {showLoader ? (
                <div className="col-11 p-4">
                  <div class="spinner-grow bg-cprimary" role="status"></div>
                  <p className="fs-16 fw-500 fc-cprimary m-0">
                    Please wait while we saving your request...
                  </p>
                </div>
              ) : (
                <div className="my-3 mt-4">
                  <button
                    onClick={handleModifyRequest}
                    type="button"
                    className="btn btn-cprimary fw-400 fs-14 ml-auto fs-16"
                  >
                    Save the changes
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
